<template>
  <v-app>
    <v-content>
      <nuxt></nuxt>
    </v-content>
    <snack-bar-container></snack-bar-container>
  </v-app>
</template>

<script>
import SnackBarContainer from '~/components/organisms/SnackBarContainer'

export default {
  components: {
    SnackBarContainer
  }
}
</script>
