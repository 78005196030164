import { Auth } from 'aws-amplify'

export default async ({ store }) => {
  try {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()
    store.commit('setAccessToken', token)
  } catch (error) {
    store.commit('setAccessToken', false)
  }
}
