<template>
  <v-overlay :value="loading" z-index="10000">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: this.isLoading
    }
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    }
  }
}
</script>

<style scoped></style>
