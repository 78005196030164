<template>
  <v-navigation-drawer
    v-model="drawer"
    class="side-menu-container"
    :width="300"
    app
  >
    <v-list-item
      v-for="item in isLogin ? menuAfterAuth : menuBeforeAuth"
      :key="item.text"
      @click="item.action()"
    >
      <v-list-item-title>{{ item.text }}</v-list-item-title>
    </v-list-item>
    <div class="spacer" />

    <div class="close-container" @click="drawer = false">
      <div class="close" />
    </div>
  </v-navigation-drawer>
</template>

<script>
import config from '~/config'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    drawer: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    menuAfterAuth() {
      return [
        {
          text: 'シリアルコード入力',
          action: () => {
            this.$router.push('/registerCodes')
          }
        },
        {
          text: '入力済コード確認',
          action: () => {
            this.$router.push('/codes/organizations')
          }
        },
        {
          text: '予約済みイベント一覧',
          action: () => {
            this.$router.push('/events')
          }
        },
        {
          text: '電子チケット一覧',
          action: () => {
            this.$router.push('/offline_events')
          }
        },
        {
          text: 'フォトギャラリー',
          action: () => {
            this.$router.push('/garally')
          }
        },
        {
          text: '抽選エントリー状況',
          action: () => {
            this.$router.push('/lottery/confirm')
          }
        },
        {
          text: 'マイページ',
          action: () => {
            this.$router.push('/mypage')
          }
        },
        {
          text: 'ライブ配信一覧',
          action: () => {
            this.$router.push('/streamings')
          }
        },
        {
          text: '外部サイト連携',
          action: () => {
            this.$router.push('/external/entry')
          }
        },
        ...this.commonItems,
        {
          text: 'ログアウト',
          action: async () => {
            await this.logout()
            this.$router.push('/login')
          }
        }
      ].filter((item) => item)
    },
    menuBeforeAuth() {
      return [
        {
          text: 'ログイン',
          action: () => {
            this.$router.push('/login')
          }
        },
        {
          text: '会員登録',
          action: () => {
            this.$router.push('/signup')
          }
        },
        ...this.commonItems
      ]
    },
    commonItems() {
      const commonItems = [
        {
          text: '使い方説明',
          action: () => {
            if (!window.WOVN) {
              window.open(config.instructionsUrl, '_blank')
              return
            }
            if (window.WOVN.io.getCurrentLang().code === 'ja') {
              window.open(config.instructionsUrl, '_blank')
            } else {
              window.open(config.instructionsEnUrl, '_blank')
            }
          }
        },
        {
          text: 'よくある質問',
          action: () => {
            this.$router.push('/faq')
          }
        },
        {
          text: '問い合わせ',
          action: () => {
            this.$router.push('/support')
          }
        },
        {
          text: '利用規約',
          action: () => {
            this.$router.push('terms')
          }
        },
        {
          text: 'プライバシーポリシー',
          action: () => {
            this.$router.push('privacy')
          }
        }
      ]

      return commonItems
    }
  },
  methods: {
    async logout() {
      await this.$authRepository.logout()
      this.$router.push('/login')
      setTimeout(() => {
        this.$store.commit('setAccessToken', undefined)
        this.$store.commit('setCurrentUser', undefined)
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.side-menu-container::v-deep {
  text-align: left;
  padding-top: 64px;

  .v-list-item {
    height: 58px;
    padding-left: 30px;
    padding-right: 24px;

    .v-list-item__title {
      color: #4a4a4a;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.close-container {
  position: absolute;
  top: 20px;
  left: 30px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.close {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 20px 0 7px;
  padding: 0;
  width: 1px;
  height: 21px;
  background: #c4c4c4;
  transform: rotate(45deg);
}
.close:before {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: -10px;
  width: 21px;
  height: 1px;
  margin-top: -1px;
  background: #c4c4c4;
}

.spacer {
  height: 100px;
}
</style>
