import HttpRepository from './httpRepository'

export default class EventLotteryRepository extends HttpRepository {
  getLottery(id) {
    return this.get(`/v1/lottery/index/${id}`)
  }

  getCampaignInfo(campaignId) {
    return this.get(`/v1/campaign/lottery/${campaignId}`)
  }

  lottery({ campaignId, codes, date, castId, extraInfo }) {
    const params = new URLSearchParams()
    params.append('campaign_id', campaignId)
    if (codes) {
      params.append('codes', codes)
    }
    if (date) {
      params.append('date', date)
    }
    if (castId) {
      params.append('cast_id', castId)
    }
    if (extraInfo) {
      params.append('extra_info', extraInfo)
    }

    return this.post('/v1/lottery', params)
  }

  confirm() {
    return this.get('/v1/lottery/confirm')
  }

  twice4({ castId, code }) {
    const params = new URLSearchParams()
    params.append('cast_id', castId)
    params.append('code', code)

    return this.post('/v1/lottery/twice4', params)
  }
}
