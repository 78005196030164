export default class MockLotteryRepository {
  fetch(liveId) {
    return Promise.resolve({
      cast_name: '谷口めぐ',
      open_date: '2020-12-01',
      part: '3部',
      serials: ['23BHA6hatN', '23bRK7PnHs', '23EDRSep6g'],
      campaign_name: 'サイン会'
    })
  }

  lottery(code) {
    return Promise.resolve({ win: Date.now() % 2 === 1 })
  }
}
