import { Auth } from 'aws-amplify'
import HttpRepository from './httpRepository'

export default class AuthRepository extends HttpRepository {
  async signup({
    email,
    lastName,
    firstName,
    password,
    lastNameKana,
    firstNameKana,
    lastNameAlphabet,
    firstNameAlphabet,
    phoneNumber,
    zipcode,
    prefecture,
    city,
    banchi,
    sex,
    job,
    birthday,
    parentLastName,
    parentFirstName,
    parent
  }) {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        family_name: lastName || '',
        given_name: firstName || '',
        'custom:last_name_kana': lastNameKana || '',
        'custom:first_name_kana': firstNameKana || '',
        'custom:last_name_alphabet': lastNameAlphabet || '',
        'custom:first_name_alphabet': firstNameAlphabet || '',
        'custom:phone_number': phoneNumber || '',
        'custom:zipcode': zipcode || '',
        'custom:prefecture': prefecture || '',
        'custom:city': city || '',
        'custom:banchi': banchi || '',
        'custom:sex': sex || '',
        'custom:job': job || '',
        'custom:birthday': birthday || '',
        'custom:parent_last_name': parentLastName || '',
        'custom:parent_first_name': parentFirstName || '',
        'custom:parent': parent ? 'true' : 'false'
      }
    })
  }

  async verifyCodeAndSignin({ email, code, password }) {
    await Auth.confirmSignUp(email, code)
    const res = await Auth.signIn(email, password)
    const token = res.signInUserSession.idToken.jwtToken

    this.axios.setHeader('X-Authorization', token)

    await this.post('/v1/user/register')
    return token
  }

  async verifyCode({ email, code }) {
    await Auth.confirmSignUp(email, code)
  }

  async resendVerifyCode({ email }) {
    await Auth.resendSignUp(email)
  }

  async login({ email, password }) {
    const res = await Auth.signIn(email, password)
    const token = res.signInUserSession.idToken.jwtToken
    this.axios.setHeader('X-Authorization', token)
    await this.post('/v1/user/register').catch(async (err) => {
      await Auth.signOut()
      throw err
    })
    return token
  }

  async logout() {
    this.axios.setHeader('X-Authorization', undefined)
    await Auth.signOut().catch(() => {
      // 握りつぶす
    })
  }

  async resetPassword(email) {
    await Auth.forgotPassword(email)
  }

  async confirmResetPassword(email, code, password) {
    await Auth.forgotPasswordSubmit(email, code, password)
  }

  async getCurrentUserInfo() {
    const data = await this.get('/v1/user/info')
    return data.result
  }

  async uploadCertification(file) {
    const params = new URLSearchParams()
    params.append('user', file)
    await this.post(`/v1/user/upload`, params)
  }

  async updateUser({
    lastNameKana,
    firstNameKana,
    lastNameAlphabet,
    firstNameAlphabet,
    phoneNumber,
    zipcode,
    prefecture,
    city,
    banchi,
    sex,
    job,
    birthday,
    parentLastName,
    parentFirstName,
    parent
  }) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      'custom:last_name_kana': lastNameKana || '',
      'custom:first_name_kana': firstNameKana || '',
      'custom:last_name_alphabet': lastNameAlphabet || '',
      'custom:first_name_alphabet': firstNameAlphabet || '',
      'custom:phone_number': phoneNumber || '',
      'custom:zipcode': zipcode || '',
      'custom:prefecture': prefecture || '',
      'custom:city': city || '',
      'custom:banchi': banchi || '',
      'custom:sex': sex || '',
      'custom:job': job || '',
      'custom:birthday': birthday || '',
      'custom:parent_last_name': parentLastName || '',
      'custom:parent_first_name': parentFirstName || '',
      'custom:parent': parent ? 'true' : 'false'
    })

    const params = new URLSearchParams()
    params.append('last_name_kana', lastNameKana)
    params.append('first_name_kana', firstNameKana)
    params.append('last_name_alphabet', lastNameAlphabet)
    params.append('first_name_alphabet', firstNameAlphabet)
    params.append('phone_number', phoneNumber)
    params.append('zipcode', zipcode)
    params.append('prefecture', prefecture)
    params.append('city', city)
    params.append('banchi', banchi)
    params.append('sex', sex)
    params.append('job', job)
    params.append('birthday', birthday)
    if (parent) {
      params.append('parent', parent)
    }
    if (parentLastName) {
      params.append('parent_last_name', parentLastName)
    }
    if (parentFirstName) {
      params.append('parent_first_name', parentFirstName)
    }

    return this.put('/v1/user', params)
  }

  checkPhoneNumber(phoneNumber) {
    return this.get('/v1/user/checkduplicate', {
      params: {
        phone_number: phoneNumber
      }
    })
  }
}
