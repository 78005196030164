export default (context, inject) => {
  const handler = {
    handle: ({ error }) => {
      let errorMessage = '予期せぬエラーが発生しました'
      // axiosのエラーを拾うため
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        errorMessage = error.response.data.error
      }

      context.error({
        statusCode: 400,
        message: errorMessage
      })
    },
    isRequireCertificate: ({ error }) => {
      return error.response?.data?.error_code === 'required_certificate'
    },
    isRequireIndividualInformation: ({ error }) => {
      return (
        error.response?.data?.error_code === 'required_individual_information'
      )
    }
  }

  context.$error = handler
  inject('error', handler)
}
