import HttpRepository from './httpRepository'

export default class ExternalRepository extends HttpRepository {
  receipt({ id, password }) {
    const params = new URLSearchParams()
    params.append('external_user_id', id)
    params.append('external_user_password', password)

    return this.post('/v1/serial/receipt', params)
  }

  split({ liveId, splitNums }) {
    const params = new URLSearchParams()
    params.append('live_id', liveId)
    params.append('split_nums', splitNums.join(','))
    return this.post('/v1/serial/split', params)
  }

  fetchIntegration(liveId) {
    return this.get(`/v1/serial/integration/${liveId}`)
  }

  integration({ liveIds }) {
    const params = new URLSearchParams()
    params.append('live_ids', liveIds.join(','))

    return this.post('/v1/serial/integration', params)
  }

  fetchIncrease({ part, eventId }) {
    return this.get(`/v1/serial/oshimashi`, {
      params: {
        part,
        event_id: eventId
      }
    })
  }

  increase({ liveId, eventId, oshimashiNum }) {
    const params = new URLSearchParams()
    params.append('live_id', liveId)
    params.append('event_id', eventId)
    params.append('oshimashi_num', oshimashiNum)
    return this.post('/v1/serial/oshimashi', params)
  }
}
