import HttpRepository from './httpRepository'

export default class LotteryRepository extends HttpRepository {
  fetch(liveId) {
    return this.get(`/v1/serial/lottery/${liveId}`)
  }

  lottery(code) {
    return this.put(`/v1/serial/lottery/${code}`)
  }

  kyc(code) {
    const params = new URLSearchParams()
    params.append('code', code)
    return this.post('/v1/lottery/realkyc', params)
  }
}
