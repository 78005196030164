import HttpRepository from './httpRepository'

export default class SystemRepository extends HttpRepository {
  async maintenance() {
    const { maintenance } = await this.get(
      'https://withlive-data.s3-ap-northeast-1.amazonaws.com/maintenance.json'
    )
    return maintenance
  }
}
