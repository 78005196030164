import { Auth } from 'aws-amplify'
import config from '~/config'

export default ({ store, redirect, route }) => {
  if (!store.state.accessToken) {
    Auth.signOut()
    localStorage.setItem(config.redirectKey, route.fullPath)
    redirect('/login')
  }
}
