import { Auth } from 'aws-amplify'

export default ({ store, redirect }) => {
  if (!store.state.currentUser) {
    store.commit('setAccessToken', undefined)
    Auth.signOut()
    redirect('/login')
    return
  }
  if (!store.state.currentUser.image) {
    redirect('/upload')
  }
}
