import Vue from 'vue'

Vue.mixin({
  methods: {
    noticeError(error) {
      if (error.code && error.name && error.message) {
        try {
          this.$sentry.captureException(new Error(JSON.stringify(error)))
        } catch (error) {
          this.$sentry.captureException(new Error(error))
        }
      } else {
        let message = ''
        if (error.response && error.response.data) {
          message = error.response.data.error || ''
        }
        this.$sentry.captureException(error, { apiError: message })
      }
    }
  }
})
