<template>
  <v-app dark>
    <div class="page">
      <div class="page-title">
        エラーが発生しました
      </div>
      <div class="page-message">
        {{ errorMessage }}
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    errorMessage() {
      if (this.error.statusCode === 404) {
        return 'お探しのページは存在しません'
      }
      return this.error.message
    }
  },
  head() {
    return {
      title: 'エラーが発生しました'
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  padding: 16px;

  &-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}
</style>
