import HttpRepository from './httpRepository'

export default class ContactRepository extends HttpRepository {
  async send({ name, phone, email, contact, type, number, ua }) {
    const params = new URLSearchParams()
    params.append('name', name)
    params.append('phone', phone)
    params.append('email', email)
    params.append('contact', contact)
    params.append('type', type)
    params.append('number', number)
    params.append('ua', ua)
    const data = await this.post('/v1/contact', params)
    return data
  }

  async getList() {
    const data = await this.get('/v1/contact/list')
    return data.result
  }
}
