import { Auth } from 'aws-amplify'
import config from '~/config'

export default ({ $axios, redirect, store, route }) => {
  $axios.onResponseError(async (error) => {
    if (error.response && error.response.status === 401) {
      store.commit('setAccessToken', undefined)
      localStorage.setItem(config.redirectKey, route.fullPath)
      await Auth.signOut()
      redirect('/login')
      return
    }
    throw error
  })

  $axios.onRequest(async (request) => {
    try {
      const session = await Auth.currentSession()
      const token = session.getIdToken().getJwtToken()

      if (token) {
        request.headers.common['X-Authorization'] = token
      }
      return request
    } catch (error) {
      // 握りつぶし
      return request
    }
  })
}
