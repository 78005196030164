import HttpRepository from './httpRepository'

export default class StreamingRepository extends HttpRepository {
  async watch({ liveId }) {
    const params = new URLSearchParams()

    params.append('ua', navigator.userAgent)

    const data = await this.post(`/v1/streaming/watch/${liveId}`, params, {
      withCredentials: true
    })
    return data
  }

  async list() {
    const data = await this.get('/v1/streaming/list')
    return data
  }

  comment({ liveId, message }) {
    const params = new URLSearchParams()
    params.append('streaming_id', liveId)
    params.append('message', message)
    return this.post('/v1/comment', params)
  }

  deleteComment({ commentId }) {
    return this.delete(`/v1/comment/${commentId}`)
  }

  async getCommentList({ liveId, commentId, count }) {
    const messages = await this.get(`v1/comment/list/${liveId}`, {
      params: {
        comment_id: commentId,
        num: count || 100
      }
    })
    return messages.comments
  }

  async watchAdmin({ liveId }) {
    const data = await this.post(
      `/v1/streaming/watchadmin/${liveId}`,
      {},
      {
        withCredentials: true
      }
    )
    return data
  }

  commentAdmin({ liveId, message }) {
    const params = new URLSearchParams()
    params.append('streaming_id', liveId)
    params.append('message', message)
    return this.post('/v1/comment/admin', params)
  }

  changeNameSign({ code, nameSign }) {
    const params = new URLSearchParams()
    params.append('name_sign', nameSign)
    params.append('code', code)
    return this.put('/v1/streaming/userinfo', params)
  }
}
