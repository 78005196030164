import HttpRepository from './httpRepository'
import { toDataUrl } from '~/helpers/file'

export default class PhotoRepository extends HttpRepository {
  async fetch() {
    const { result } = await this.get('/v1/talk/photo')
    return result
  }

  async fetchDataUrl(url) {
    if (!url) {
      return undefined
    }
    const blob = await this.get(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'image/png',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    return toDataUrl(blob)
  }
}
