const configs = {
  mock: {
    awsConfig: {
      Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_Kk6JbT74B',
        userPoolWebClientId: '198br006001vla30u4jk3c25sg'
      }
    },
    apiBaseUrl: 'https://staging-api.withlive-app.com',
    winMp3Url:
      'https://withlive-data.s3-ap-northeast-1.amazonaws.com/withlive-mg/akb/music/win.mp3',
    loseMp3Url:
      'https://withlive-data.s3-ap-northeast-1.amazonaws.com/withlive-mg/akb/music/lose.mp3',
    agoraAppId: '',
    withliveOrigin: 'https://staging.withlive.jp'
  },
  local: {
    awsConfig: {
      Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_Kk6JbT74B',
        userPoolWebClientId: '198br006001vla30u4jk3c25sg'
      }
    },
    apiBaseUrl: 'https://staging-api.withlive-app.com',
    winMp3Url:
      'https://withlive-data.s3-ap-northeast-1.amazonaws.com/withlive-mg/akb/music/win.mp3',
    loseMp3Url:
      'https://withlive-data.s3-ap-northeast-1.amazonaws.com/withlive-mg/akb/music/lose.mp3',
    agoraAppId: '30c649a7e7c041c7bd628f673246715e',
    withliveOrigin: 'https://staging.withlive.jp'
  },
  development: {
    awsConfig: {
      Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_Kk6JbT74B',
        userPoolWebClientId: '198br006001vla30u4jk3c25sg'
      }
    },
    apiBaseUrl: 'https://staging-api.withlive-app.com',
    winMp3Url:
      'https://withlive-data.s3-ap-northeast-1.amazonaws.com/withlive-mg/akb/music/win.mp3',
    loseMp3Url:
      'https://withlive-data.s3-ap-northeast-1.amazonaws.com/withlive-mg/akb/music/lose.mp3',
    agoraAppId: '30c649a7e7c041c7bd628f673246715e',
    withliveOrigin: 'https://staging.withlive.jp'
  },
  staging: {
    awsConfig: {
      Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_Kk6JbT74B',
        userPoolWebClientId: '198br006001vla30u4jk3c25sg'
      }
    },
    apiBaseUrl: 'https://staging-api.withlive-app.com',
    winMp3Url:
      'https://withlive-data.s3-ap-northeast-1.amazonaws.com/withlive-mg/akb/music/win.mp3',
    loseMp3Url:
      'https://withlive-data.s3-ap-northeast-1.amazonaws.com/withlive-mg/akb/music/lose.mp3',
    agoraAppId: '30c649a7e7c041c7bd628f673246715e',
    withliveOrigin: 'https://staging.withlive.jp'
  },
  production: {
    awsConfig: {
      Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_TO7bZlIYH',
        userPoolWebClientId: '2979u04t1dp1mlmsbj8tjp38bq'
      }
    },
    apiBaseUrl: 'https://api.withlive-app.com',
    winMp3Url:
      'https://withlive-data-stg.s3-ap-northeast-1.amazonaws.com/akb/music/win.mp3',
    loseMp3Url:
      'https://withlive-data-stg.s3-ap-northeast-1.amazonaws.com/akb/music/lose.mp3',
    agoraAppId: 'fdf8543a4ec64c1791833ca15b73ad39',
    withliveOrigin: 'https://www.withlive.jp'
  },
  phptest: {
    awsConfig: {
      Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_TO7bZlIYH',
        userPoolWebClientId: '2979u04t1dp1mlmsbj8tjp38bq'
      }
    },
    apiBaseUrl: 'https://mg-api-docker.withlive-app.com',
    winMp3Url:
      'https://withlive-data-stg.s3-ap-northeast-1.amazonaws.com/akb/music/win.mp3',
    loseMp3Url:
      'https://withlive-data-stg.s3-ap-northeast-1.amazonaws.com/akb/music/lose.mp3',
    agoraAppId: 'fdf8543a4ec64c1791833ca15b73ad39',
    withliveOrigin: 'https://www.withlive.jp'
  }
}

const commons = {
  appleStoreUrl: 'https://apps.apple.com/jp/app/id1525271943',
  googlePlayStoreUrl:
    'https://play.google.com/store/apps/details?id=jp.withlive.greet.meet',
  instructionsUrl:
    'https://withlive-data.s3.ap-northeast-1.amazonaws.com/withlive-mg/how_to_use/Instructions.pdf',
  instructionsEnUrl:
    'https://withlive-data.s3.ap-northeast-1.amazonaws.com/withlive-mg/how_to_use/Instructions_en.pdf',
  googleMapApiKey: 'AIzaSyC7ObFew2juwgLI6ihPqIQfy6Nn05ZWUwU',
  redirectKey: 'redirect',
  staffPassword: '3550'
}

export default {
  ...configs[process.env.STAGE],
  stage: process.env.STAGE,
  ...commons
}
