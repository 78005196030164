export const toDataUrl = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}

export const createWhiteImageDataUrl = () => {
  const canvas = document.createElement('canvas')
  canvas.width = 200
  canvas.height = 200
  const dataURL = canvas.toDataURL('image/png')
  return dataURL
}
