<template>
  <v-snackbar :timeout="-1" :value="!!toast.message" :color="toast.type" top>
    <span class="text">{{ toast.message }}</span>
    <v-icon color="white" @click="handleClickClose">fa-times-circle</v-icon>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['toast'])
  },
  methods: {
    handleClickClose() {
      this.$store.dispatch('hideErrorToast')
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  font-weight: 600;
  white-space: pre-wrap;
}
</style>
