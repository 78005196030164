export default class HttpRepository {
  constructor(axios) {
    this.axios = axios
  }

  get(url, options = {}) {
    return this.axios.$get(url, options)
  }

  post(url, data, options = {}) {
    return this.axios.$post(url, data, options)
  }

  patch(url, data) {
    return this.axios.$patch(url, data)
  }

  put(url, data) {
    return this.axios.$put(url, data)
  }

  delete(url) {
    return this.axios.$delete(url)
  }
}
