<template>
  <div>
    <v-app-bar
      class="my-header"
      flat
      fixed
      app
      height="64px"
      :color="transparent ? 'transparent' : 'white'"
    >
      <div class="header">
        <div class="header-left">
          <span class="header-left-icon" @click.stop="drawer = true">
            <v-icon>mdi-menu</v-icon>
          </span>
          <!-- <img :src="logoUrl" class="header-left-logo" alt="logo" /> -->
        </div>
        <div class="header-right">
          <slot name="right" />
        </div>
      </div>
    </v-app-bar>
    <side-menu v-model="drawer" :is-login="isLogin" />
  </div>
</template>

<script>
import SideMenu from '~/components/organisms/SideMenu'

export default {
  components: {
    SideMenu
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    drawer: false
  })
}
</script>

<style lang="scss" scoped>
.my-header {
  background-color: white;
  ::v-deep.v-toolbar__content {
    padding: {
      left: 12px;
      right: 24px;
    }
  }
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;

  &-left {
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 12px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    &-logo {
      height: 26px;
    }
  }
}
</style>
