import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _749328b8 = () => interopDefault(import('../pages/akb48.vue' /* webpackChunkName: "pages/akb48" */))
const _f9bdc13c = () => interopDefault(import('../pages/certification.vue' /* webpackChunkName: "pages/certification" */))
const _30acd90c = () => interopDefault(import('../pages/cix.vue' /* webpackChunkName: "pages/cix" */))
const _392e88d1 = () => interopDefault(import('../pages/codes/index.vue' /* webpackChunkName: "pages/codes/index" */))
const _81ee27be = () => interopDefault(import('../pages/events.vue' /* webpackChunkName: "pages/events" */))
const _7852dbbe = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _3ef805fb = () => interopDefault(import('../pages/garally/index.vue' /* webpackChunkName: "pages/garally/index" */))
const _0b509010 = () => interopDefault(import('../pages/how.vue' /* webpackChunkName: "pages/how" */))
const _4db79f5e = () => interopDefault(import('../pages/Instructions.pdf.vue' /* webpackChunkName: "pages/Instructions.pdf" */))
const _6049c134 = () => interopDefault(import('../pages/jo1.vue' /* webpackChunkName: "pages/jo1" */))
const _51a64bd0 = () => interopDefault(import('../pages/junk.vue' /* webpackChunkName: "pages/junk" */))
const _2a14da91 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _173fed3b = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _63ad377a = () => interopDefault(import('../pages/mypage.vue' /* webpackChunkName: "pages/mypage" */))
const _5e60949a = () => interopDefault(import('../pages/niziu.vue' /* webpackChunkName: "pages/niziu" */))
const _3bbcc7fd = () => interopDefault(import('../pages/offline_events.vue' /* webpackChunkName: "pages/offline_events" */))
const _4279e330 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _3608052a = () => interopDefault(import('../pages/registerCodes.vue' /* webpackChunkName: "pages/registerCodes" */))
const _092fa577 = () => interopDefault(import('../pages/reset.vue' /* webpackChunkName: "pages/reset" */))
const _1aee2ee2 = () => interopDefault(import('../pages/seventeen.vue' /* webpackChunkName: "pages/seventeen" */))
const _4fb6f323 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _692c7684 = () => interopDefault(import('../pages/straykids.vue' /* webpackChunkName: "pages/straykids" */))
const _193ae9c8 = () => interopDefault(import('../pages/streamings/index.vue' /* webpackChunkName: "pages/streamings/index" */))
const _7a85c3f7 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _0a6af5af = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _290d9e92 = () => interopDefault(import('../pages/thegazette.vue' /* webpackChunkName: "pages/thegazette" */))
const _974e195a = () => interopDefault(import('../pages/twice/index.vue' /* webpackChunkName: "pages/twice/index" */))
const _74d46509 = () => interopDefault(import('../pages/upload.vue' /* webpackChunkName: "pages/upload" */))
const _38de4c60 = () => interopDefault(import('../pages/app/faq.vue' /* webpackChunkName: "pages/app/faq" */))
const _6595b60a = () => interopDefault(import('../pages/app/how.vue' /* webpackChunkName: "pages/app/how" */))
const _78f2ef7c = () => interopDefault(import('../pages/app/privacy.vue' /* webpackChunkName: "pages/app/privacy" */))
const _08db2dee = () => interopDefault(import('../pages/app/support.vue' /* webpackChunkName: "pages/app/support" */))
const _c3bc917e = () => interopDefault(import('../pages/app/terms.vue' /* webpackChunkName: "pages/app/terms" */))
const _a81747da = () => interopDefault(import('../pages/codes/casts.vue' /* webpackChunkName: "pages/codes/casts" */))
const _5b865504 = () => interopDefault(import('../pages/codes/dates.vue' /* webpackChunkName: "pages/codes/dates" */))
const _031c74ac = () => interopDefault(import('../pages/codes/events.vue' /* webpackChunkName: "pages/codes/events" */))
const _1036e8e5 = () => interopDefault(import('../pages/codes/groups.vue' /* webpackChunkName: "pages/codes/groups" */))
const _325dab9f = () => interopDefault(import('../pages/codes/organizations.vue' /* webpackChunkName: "pages/codes/organizations" */))
const _6006700d = () => interopDefault(import('../pages/codes/select.vue' /* webpackChunkName: "pages/codes/select" */))
const _6d8e488e = () => interopDefault(import('../pages/external/entry/index.vue' /* webpackChunkName: "pages/external/entry/index" */))
const _3c8e6b5b = () => interopDefault(import('../pages/garally/photo.vue' /* webpackChunkName: "pages/garally/photo" */))
const _5e1ec8a2 = () => interopDefault(import('../pages/lottery/confirm.vue' /* webpackChunkName: "pages/lottery/confirm" */))
const _2bfe690a = () => interopDefault(import('../pages/lottery/select.vue' /* webpackChunkName: "pages/lottery/select" */))
const _3f7a80d0 = () => interopDefault(import('../pages/signup/detail.vue' /* webpackChunkName: "pages/signup/detail" */))
const _7d049b32 = () => interopDefault(import('../pages/streamings/help.vue' /* webpackChunkName: "pages/streamings/help" */))
const _e7085bc4 = () => interopDefault(import('../pages/event/lottery/campaign/_id.vue' /* webpackChunkName: "pages/event/lottery/campaign/_id" */))
const _065f2a27 = () => interopDefault(import('../pages/event/lottery/_id.vue' /* webpackChunkName: "pages/event/lottery/_id" */))
const _91912656 = () => interopDefault(import('../pages/gacha/_id.vue' /* webpackChunkName: "pages/gacha/_id" */))
const _394c7b9c = () => interopDefault(import('../pages/lottery/_id.vue' /* webpackChunkName: "pages/lottery/_id" */))
const _dcee8ff2 = () => interopDefault(import('../pages/streamings/_id/index.vue' /* webpackChunkName: "pages/streamings/_id/index" */))
const _4367cdd8 = () => interopDefault(import('../pages/top/_name.vue' /* webpackChunkName: "pages/top/_name" */))
const _7fea04b7 = () => interopDefault(import('../pages/streamings/_id/archive.vue' /* webpackChunkName: "pages/streamings/_id/archive" */))
const _8f0fac46 = () => interopDefault(import('../pages/streamings/_id/administrator/internal/index.vue' /* webpackChunkName: "pages/streamings/_id/administrator/internal/index" */))
const _ae774de6 = () => interopDefault(import('../pages/streamings/_id/administrator/internal/archive.vue' /* webpackChunkName: "pages/streamings/_id/administrator/internal/archive" */))
const _606d377a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/akb48",
    component: _749328b8,
    name: "akb48"
  }, {
    path: "/certification",
    component: _f9bdc13c,
    name: "certification"
  }, {
    path: "/cix",
    component: _30acd90c,
    name: "cix"
  }, {
    path: "/codes",
    component: _392e88d1,
    name: "codes"
  }, {
    path: "/events",
    component: _81ee27be,
    name: "events"
  }, {
    path: "/faq",
    component: _7852dbbe,
    name: "faq"
  }, {
    path: "/garally",
    component: _3ef805fb,
    name: "garally"
  }, {
    path: "/how",
    component: _0b509010,
    name: "how"
  }, {
    path: "/Instructions.pdf",
    component: _4db79f5e,
    name: "Instructions.pdf"
  }, {
    path: "/jo1",
    component: _6049c134,
    name: "jo1"
  }, {
    path: "/junk",
    component: _51a64bd0,
    name: "junk"
  }, {
    path: "/login",
    component: _2a14da91,
    name: "login"
  }, {
    path: "/maintenance",
    component: _173fed3b,
    name: "maintenance"
  }, {
    path: "/mypage",
    component: _63ad377a,
    name: "mypage"
  }, {
    path: "/niziu",
    component: _5e60949a,
    name: "niziu"
  }, {
    path: "/offline_events",
    component: _3bbcc7fd,
    name: "offline_events"
  }, {
    path: "/privacy",
    component: _4279e330,
    name: "privacy"
  }, {
    path: "/registerCodes",
    component: _3608052a,
    name: "registerCodes"
  }, {
    path: "/reset",
    component: _092fa577,
    name: "reset"
  }, {
    path: "/seventeen",
    component: _1aee2ee2,
    name: "seventeen"
  }, {
    path: "/signup",
    component: _4fb6f323,
    name: "signup"
  }, {
    path: "/straykids",
    component: _692c7684,
    name: "straykids"
  }, {
    path: "/streamings",
    component: _193ae9c8,
    name: "streamings"
  }, {
    path: "/support",
    component: _7a85c3f7,
    name: "support"
  }, {
    path: "/terms",
    component: _0a6af5af,
    name: "terms"
  }, {
    path: "/thegazette",
    component: _290d9e92,
    name: "thegazette"
  }, {
    path: "/twice",
    component: _974e195a,
    name: "twice"
  }, {
    path: "/upload",
    component: _74d46509,
    name: "upload"
  }, {
    path: "/app/faq",
    component: _38de4c60,
    name: "app-faq"
  }, {
    path: "/app/how",
    component: _6595b60a,
    name: "app-how"
  }, {
    path: "/app/privacy",
    component: _78f2ef7c,
    name: "app-privacy"
  }, {
    path: "/app/support",
    component: _08db2dee,
    name: "app-support"
  }, {
    path: "/app/terms",
    component: _c3bc917e,
    name: "app-terms"
  }, {
    path: "/codes/casts",
    component: _a81747da,
    name: "codes-casts"
  }, {
    path: "/codes/dates",
    component: _5b865504,
    name: "codes-dates"
  }, {
    path: "/codes/events",
    component: _031c74ac,
    name: "codes-events"
  }, {
    path: "/codes/groups",
    component: _1036e8e5,
    name: "codes-groups"
  }, {
    path: "/codes/organizations",
    component: _325dab9f,
    name: "codes-organizations"
  }, {
    path: "/codes/select",
    component: _6006700d,
    name: "codes-select"
  }, {
    path: "/external/entry",
    component: _6d8e488e,
    name: "external-entry"
  }, {
    path: "/garally/photo",
    component: _3c8e6b5b,
    name: "garally-photo"
  }, {
    path: "/lottery/confirm",
    component: _5e1ec8a2,
    name: "lottery-confirm"
  }, {
    path: "/lottery/select",
    component: _2bfe690a,
    name: "lottery-select"
  }, {
    path: "/signup/detail",
    component: _3f7a80d0,
    name: "signup-detail"
  }, {
    path: "/streamings/help",
    component: _7d049b32,
    name: "streamings-help"
  }, {
    path: "/event/lottery/campaign/:id?",
    component: _e7085bc4,
    name: "event-lottery-campaign-id"
  }, {
    path: "/event/lottery/:id?",
    component: _065f2a27,
    name: "event-lottery-id"
  }, {
    path: "/gacha/:id?",
    component: _91912656,
    name: "gacha-id"
  }, {
    path: "/lottery/:id?",
    component: _394c7b9c,
    name: "lottery-id"
  }, {
    path: "/streamings/:id",
    component: _dcee8ff2,
    name: "streamings-id"
  }, {
    path: "/top/:name?",
    component: _4367cdd8,
    name: "top-name"
  }, {
    path: "/streamings/:id/archive",
    component: _7fea04b7,
    name: "streamings-id-archive"
  }, {
    path: "/streamings/:id/administrator/internal",
    component: _8f0fac46,
    name: "streamings-id-administrator-internal"
  }, {
    path: "/streamings/:id/administrator/internal/archive",
    component: _ae774de6,
    name: "streamings-id-administrator-internal-archive"
  }, {
    path: "/",
    component: _606d377a,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
