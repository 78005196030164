export const state = () => ({
  accessToken: undefined,
  toast: {
    type: null,
    message: null,
    timeout: null
  },
  currentUser: undefined
})

export const getters = {}

export const mutations = {
  setAccessToken(state, token) {
    state.accessToken = token
  },
  setCurrentUser(state, user) {
    state.currentUser = user
  },
  setToast(state, { type, message }) {
    state.toast = {
      ...state.toast,
      type,
      message
    }
  },
  setToastTimeout(state, { timeout }) {
    state.toast = {
      ...state.toast,
      timeout
    }
  }
}

export const actions = {
  showErrorToast({ commit, state }, { message, error }) {
    let errorMessage = message || '予期せぬエラーが発生しました'
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      errorMessage = error.response.data.error
    }

    clearTimeout(state.toast.timeout)
    commit('setToast', { type: 'error', message: errorMessage })
    const timeout = setTimeout(() => {
      commit('setToast', { type: null, message: null })
      commit('setToastTimeout', { timeout: null })
    }, 4000)
    commit('setToastTimeout', { timeout })
  },

  hideErrorToast({ commit, state }) {
    clearTimeout(state.toast.timeout)
    commit('setToast', { type: null, message: null })
    commit('setToastTimeout', { timeout: null })
  },

  showSuccessToast({ commit, state }, { message }) {
    clearTimeout(state.toast.timeout)
    commit('setToast', { type: 'success', message })
    const timeout = setTimeout(() => {
      commit('setToast', { type: null, message: null })
      commit('setToastTimeout', { timeout: null })
    }, 2000)
    commit('setToastTimeout', { timeout })
  },

  async setCurrentUser({ commit }) {
    try {
      const info = await this.$authRepository.getCurrentUserInfo()
      commit('setCurrentUser', info)
    } catch (error) {}
  }
}
