export const state = () => ({
  selectedPhoto: undefined
})

export const getters = {}

export const mutations = {
  setPhoto(state, photo) {
    state.selectedPhoto = photo
  }
}

export const actions = {}
