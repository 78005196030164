import config from '~/config'
import AuthRepository from '~/repository/authRepository'
import SerialRepository from '~/repository/serialRepository'
import MockSerialRepository from '~/repository/mock/mockSerialRepository'
import ContactRepository from '~/repository/contactRepository'
import SystemRepository from '~/repository/systemRepository'
import MockLotteryRepository from '~/repository/mock/mockLotteryRepository'
import LotteryRepository from '~/repository/lotteryRepository'
import PhotoRepository from '~/repository/photoRepository'
import ExternalRepository from '~/repository/externalRepository'
import StreamingRepository from '~/repository/streamingRepository'
import EventLotteryRepository from '~/repository/eventLotteryRepository'
import AddressRepository from '~/repository/addressRepository'
import OrganizationRepository from '~/repository/organizationRepository'
import GachaRepository from '~/repository/gachaRepository'

export default (context, inject) => {
  const authRepository = new AuthRepository(context.$axios)
  context.$authRepository = authRepository
  inject('authRepository', authRepository)

  const serialRepository =
    config.stage === 'mock'
      ? new MockSerialRepository()
      : new SerialRepository(context.$axios)
  context.$serialRepository = serialRepository
  inject('serialRepository', serialRepository)

  const contactRepository = new ContactRepository(context.$axios)
  context.$contactRepository = contactRepository
  inject('contactRepository', contactRepository)

  const systemRepository = new SystemRepository(context.$axios)
  context.$systemRepository = systemRepository
  inject('systemRepository', systemRepository)

  const lotteryRepository =
    config.stage === 'mock'
      ? new MockLotteryRepository()
      : new LotteryRepository(context.$axios)
  context.$lotteryRepository = lotteryRepository
  inject('lotteryRepository', lotteryRepository)

  const photoRepository = new PhotoRepository(context.$axios)
  context.$photoRepository = photoRepository
  inject('photoRepository', photoRepository)

  const externalRepository = new ExternalRepository(context.$axios)
  context.$externalRepository = externalRepository
  inject('externalRepository', externalRepository)

  const streamingRepository = new StreamingRepository(context.$axios)
  context.$streamingRepository = streamingRepository
  inject('streamingRepository', streamingRepository)

  const eventLotteryRepository = new EventLotteryRepository(context.$axios)
  context.$eventLotteryRepository = eventLotteryRepository
  inject('eventLotteryRepository', eventLotteryRepository)

  const addressRepository = new AddressRepository(context.$axios)
  context.$addressRepository = addressRepository
  inject('addressRepository', addressRepository)

  const organizationRepository = new OrganizationRepository(context.$axios)
  context.$organizationRepository = organizationRepository
  inject('organizationRepository', organizationRepository)

  const gachaRepository = new GachaRepository(context.$axios)
  context.$gachaRepository = gachaRepository
  inject('gachaRepository', gachaRepository)
}
