export default class MockSerialRepository {
  regist(codes) {
    // `POST /v1/serial/register`
    return Promise.resolve()
  }

  featchOrganizations() {
    // `GET /v1/serial/orgzanization`
    return Promise.resolve({
      organizations: [
        { id: 1, name: 'セブンティーン' },
        { id: 2, name: 'AKB48グループ' }
      ]
    })
  }

  checkSerialCodes(organizationId) {
    // `GET /v1/serial/check`
    return Promise.resolve({
      organization_id: 100,
      organization_name: 'AKB48グループ',
      serials: [
        {
          code: 'aaa',
          cast_name: 'メンバーA',
          live_id: 111
        },
        {
          code: 'bbb',
          cast_name: 'メンバーB',
          live_id: 112
        },
        {
          code: 'bbb',
          cast_name: 'メンバーC',
          live_id: 113
        },
        {
          code: 'bbb',
          cast_name: 'メンバー指定なし',
          live_id: null
        }
      ]
    })
  }

  fetchGroups(organizationId) {
    // `GET /v1/group?organization_id=1`
    return Promise.resolve({
      groups: [
        { id: 5, name: 'AKB48 teamA' },
        { id: 6, name: 'AKB48 teamB' },
        { id: 7, name: 'SKE48 teamS' },
        { id: 8, name: 'SKE48 teamK' }
      ]
    })
  }

  fetchCasts(groupId) {
    // `GET /v1/cast?groupId=6`
    return Promise.resolve({
      casts: [
        { id: 'aaaa', name: 'メンバーA' },
        { id: 'bbbb', name: 'メンバーB' },
        { id: 'cccc', name: 'メンバーC' },
        { id: 'dddd', name: 'メンバーD' }
      ]
    })
  }

  fetchAvailableEventDates(castId) {
    // `GET /v1/event/dates?cast_id=aaaaa`
    return Promise.resolve({
      dates: ['20200906', '20200910', '20200911']
    })
  }

  fetchEvents({ castId, date }) {
    // `GET /v1/event/entrylist?cast_id=aaa&date=20200903`
    return Promise.resolve({
      events: [
        {
          cast_name: 'キャストA',
          campaign_name: '握手会',
          event_id: 1,
          part: '第一部',
          open_time: '2020/10/10 12:00',
          has_capacity: true,
          is_under_kitei: false,
          capacity: 120
        },
        {
          cast_name: 'キャストA',
          campaign_name: '握手会',
          event_id: 10,
          part: '第二部',
          open_time: '2020/10/10 12:00',
          has_capacity: true,
          is_under_kitei: true,
          capacity: 120
        }
      ]
    })
  }

  fetchAvailableCodes(eventId) {
    //  `GET /v1/serial/available?evet_id=22`
    return Promise.resolve({
      serials: [
        {
          code: 'aaa',
          cast_name: 'キャストA'
        },
        {
          code: 'bbb',
          cast_name: 'キャストB'
        },
        {
          code: 'ccc',
          cast_name: '当日指定券'
        }
      ]
    })
  }

  entry(codes, eventId) {
    // `POST /v1/serial`
    return Promise.resolve({
      campaign_name: '個別握手会',
      part: '1部',
      cast_name: 'メンバー1',
      open_time: '2020-09-10 10:00:00'
    })
  }

  fetchList() {
    return Promise.resolve({
      result: [
        {
          live_id: 1,
          cast_name: 'キャストA',
          campaign_name: '握手会',
          event_id: 1,
          part: '第一部',
          open_time: '2020/10/10 12:00',
          has_capacity: true,
          capacity: '120',
          codes: ['12345', 'asbdf', 'lfkasjdh'],
          name_sign: 'Taro Yamada'
        },
        {
          live_id: 2,
          cast_name: 'キャストB',
          campaign_name: '握手会',
          event_id: 2,
          part: '第一部',
          open_time: '2020/10/10 12:00',
          has_capacity: true,
          capacity: '120',
          order: 12,
          codes: ['77777'],
          name_sign: 'Taro Yamada'
        },
        {
          live_id: 3,
          cast_name: 'キャストC',
          campaign_name: 'ハイタッチ会',
          event_id: 3,
          part: '第一部',
          open_time: '2020/10/10 15:00',
          has_capacity: false,
          capacity: '40',
          order: 1,
          codes: ['7777fff', '7771fff', '777efff', '7773fff', '7775fff'],
          name_sign: 'Taro Yamada'
        }
      ]
    })
  }

  cancel(liveId) {
    return Promise.resolve()
  }

  updateNickname(params) {
    return Promise.resolve()
  }
}
