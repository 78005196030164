<template>
  <v-app class="application">
    <v-content class="main-container">
      <nuxt></nuxt>
    </v-content>
    <snack-bar-container></snack-bar-container>
  </v-app>
</template>

<script>
import SnackBarContainer from '~/components/organisms/SnackBarContainer'

export default {
  components: {
    SnackBarContainer
  }
}
</script>

<style lang="scss" scoped>
.application::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
}
</style>
