const middleware = {}

middleware['alreadyAuth'] = require('../middleware/alreadyAuth.js')
middleware['alreadyAuth'] = middleware['alreadyAuth'].default || middleware['alreadyAuth']

middleware['checkMaintenance'] = require('../middleware/checkMaintenance.js')
middleware['checkMaintenance'] = middleware['checkMaintenance'].default || middleware['checkMaintenance']

middleware['requiredAuth'] = require('../middleware/requiredAuth.js')
middleware['requiredAuth'] = middleware['requiredAuth'].default || middleware['requiredAuth']

middleware['requiredUploadCertification'] = require('../middleware/requiredUploadCertification.js')
middleware['requiredUploadCertification'] = middleware['requiredUploadCertification'].default || middleware['requiredUploadCertification']

middleware['setAccessToken'] = require('../middleware/setAccessToken.js')
middleware['setAccessToken'] = middleware['setAccessToken'].default || middleware['setAccessToken']

middleware['setAccessTokenFromApp'] = require('../middleware/setAccessTokenFromApp.js')
middleware['setAccessTokenFromApp'] = middleware['setAccessTokenFromApp'].default || middleware['setAccessTokenFromApp']

middleware['setCurrentUser'] = require('../middleware/setCurrentUser.js')
middleware['setCurrentUser'] = middleware['setCurrentUser'].default || middleware['setCurrentUser']

export default middleware
