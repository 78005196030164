<template>
  <v-app>
    <v-content class="main-container">
      <default-header :is-login="!!$store.state.accessToken" />
      <nuxt></nuxt>
    </v-content>
    <portal-target name="outside" />
    <snack-bar-container></snack-bar-container>
  </v-app>
</template>

<script>
import SnackBarContainer from '~/components/organisms/SnackBarContainer'
import DefaultHeader from '~/components/organisms/Header'

export default {
  components: {
    SnackBarContainer,
    DefaultHeader
  }
}
</script>
