import HttpRepository from './httpRepository'
import config from '~/config'

export default class AddressRepository extends HttpRepository {
  async send({ zipcode }) {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?language=ja&sensor=false&key=${config.googleMapApiKey}&address=${zipcode}`
    )
    const json = await response.json()
    const result = json.results[0]
    if (!result) {
      return undefined
    }

    const address = result.address_components
    if (!address) {
      return undefined
    }

    const prefecture = address[address.length - 2]?.long_name
    const city = address
      .slice(1, address.length - 2)
      .reverse()
      .reduce((a, b) => a + b?.long_name, '')

    return {
      prefecture,
      city
    }
  }
}
