import HttpRepository from './httpRepository'

export default class SerialRepository extends HttpRepository {
  regist(codes) {
    const params = new URLSearchParams()
    params.append('codes', codes)

    return this.post('/v1/serial/register', params)
  }

  featchOrganizations() {
    return this.get('/v1/serial/organization')
  }

  checkSerialCodes(organizationId) {
    return this.get('/v1/serial/check', {
      params: {
        organization_id: organizationId
      }
    })
  }

  fetchGroups(organizationId) {
    return this.get('/v1/group', {
      params: {
        organization_id: organizationId
      }
    })
  }

  fetchCasts(groupId) {
    return this.get('/v1/cast', {
      params: {
        groupId
      }
    })
  }

  fetchAvailableEventDates(castId) {
    return this.get('/v1/event/dates', {
      params: {
        cast_id: castId
      }
    })
  }

  fetchEvents({ castId, date }) {
    return this.get('/v1/event/entrylist', {
      params: {
        cast_id: castId,
        date
      }
    })
  }

  fetchAvailableCodes(eventId) {
    return this.get('/v1/serial/available', {
      params: {
        event_id: eventId
      }
    })
  }

  entry(codes, eventId) {
    const params = new URLSearchParams()
    params.append('codes', codes)
    params.append('event_id', eventId)
    return this.post('/v1/serial/entry', params)
  }

  cancel(liveId) {
    return this.delete(`/v1/serial/${liveId}`)
  }

  fetchList() {
    return this.get('/v1/serial/confirm?offline_event=0')
  }

  fetchOfflineList() {
    return this.get('/v1/serial/confirm?offline_event=1')
  }

  confirmMogiriCheck(liveId) {
    return this.get(`/v1/talk/mogiricheck/${liveId}`)
  }

  mogiri(liveId) {
    return this.post(`/v1/talk/mogiri/${liveId}`)
  }

  updateNickname({ nickname, liveId, isAll }) {
    const params = {
      name_sign: nickname
    }
    if (isAll) {
      params.all_change = true
    } else {
      params.live_id = liveId
    }

    return this.put('/v1/serial', params)
  }

  fetchCampaignByCode(codes) {
    const params = new URLSearchParams()
    params.append('codes', codes)

    return this.post('/v1/serial/campaign', params)
  }

  async fetchEntryList() {
    const res = await this.get('/v1/serial/entrylist')
    return res ? res.result : []
  }

  matomeMogiri(eventId) {
    const params = new URLSearchParams()
    params.append('event_id', eventId)

    return this.post('/v1/serial/matomemogiri', params)
  }
}
