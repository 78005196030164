export default async ({ app, redirect, route }) => {
  try {
    const maintenancePath = '/maintenance'
    if (route.path !== maintenancePath) {
      const isMaintenance = await app.$systemRepository.maintenance()
      if (isMaintenance) {
        redirect(maintenancePath)
      }
    }
  } catch (error) {}
}
