import HttpRepository from './httpRepository'

export default class GachaRepository extends HttpRepository {
  getById(id) {
    return this.get('/v1/gacha/index/' + id)
  }

  gacha(id, code) {
    const params = new URLSearchParams()
    params.append('code', code)
    return this.post('/v1/gacha/' + id, params)
  }
}
