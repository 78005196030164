export default (context, inject) => {
  const toast = {
    error: ({ message, error }) => {
      let errorMessage = message || '予期せぬエラーが発生しました'
      // axiosのエラーを拾うため
      if (
        !message &&
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        errorMessage = error.response.data.error
      }
      context.store.dispatch('showErrorToast', {
        message: errorMessage
      })
    },
    success: (message) => {
      context.store.dispatch('showSuccessToast', {
        message
      })
    }
  }

  context.$toast = toast
  inject('toast', toast)
}
